import React from "react";
import { PageWrapper } from "~components/Core"
import BreadCrumbSection from "~sections/Careers/BreadCrumb";
import AboutSection from "~sections/Careers/About";
import ServiceSection from "~sections/Careers/Services";
import JobSection from "~sections/Careers/Job";
import FooterSection from "~sections/Careers/Footer";

import FooterOne from "~sections/marketing/FooterOne"

const header = {
  headerClasses:
    "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function Empleos() {
  return (
    <PageWrapper headerConfig={header}>
      {/* <BreadCrumbSection title="TALENTO" text="Nuestro enfoque es siempre encontrar <br/> a las mejores personas para trabajar."/> */}
      <div className="bannerOne_drones">
        <div>
          <h1>TALENTO</h1>
          <p>Nuestro enfoque es siempre encontrar <br /> a las mejores personas para trabajar.</p>
        </div>
      </div>
      <AboutSection />
      <ServiceSection />

      <JobSection />
      <FooterOne />
    </PageWrapper>
  )
}
